import React from "react";
import {graphql} from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import {mapEdgesToNodes} from "../lib/helpers";
import BlockText from "../components/block-text";

import {responsiveTitle1} from "../components/typography.module.css";
import * as styles from "../styles/artist-statement.module.css";

export const query = graphql`
  query ArtistStatementPageQuery {
    settings: allSanitySiteSettings {
      edges {
        node {
          author {
            _rawArtistStatement
          }
        }
      }
    }
  }
`;

const ArtistStatementPage = props => {
  const {data, errors} = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors}/>
      </Layout>
    );
  }
  const projectNodes = data && data.settings && mapEdgesToNodes(data.settings);

  return (
    <Layout>
      <SEO title="Artist Statement"/>
      <div className={styles.container}>
        {projectNodes && projectNodes[0] && projectNodes[0].author._rawArtistStatement && (
          <div className={styles.statement}>
            <h1 className={responsiveTitle1}>Artist Statment</h1>
            <BlockText blocks={projectNodes[0].author._rawArtistStatement}/>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ArtistStatementPage;
